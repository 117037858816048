<template>
  <div :class="$style.timeline">
    <div :class="$style.item">
      <button-blue-icon @click="play()">
        <inline-svg
          v-if="!isPlaying"
          :src="require('@/assets/images/icons/computer/screenshots/modal/play.svg')
          "
          alt="Воспроизведение"
          title="Воспроизведение"
          :class="$style.playSvg"
        />
        <inline-svg
          v-else
          :src="require('@/assets/images/icons/computer/screenshots/modal/pause.svg')
          "
          alt="Остановка"
          title="Остановка"
          :class="$style.playSvg"
        />
      </button-blue-icon>
      <button-blue-icon @click="moveNext()">
        <inline-svg
          :src="require('@/assets/images/icons/computer/screenshots/modal/next.svg')
          "
          alt="Вперед"
          title="Вперед"
          :class="$style.playSvg"
        />
      </button-blue-icon>
    </div>

    <svg :class="$style.svg">
      <rect :class="$style.mainLine" />
    </svg>
    <svg :class="$style.cursor">
      <rect
        :x="cursor.x"
        :class="$style.cursorSvg"
        fill="#2985BF"
      />
    </svg>
    <svg :class="$style.ruler">
      <g
        v-for="(time, index) in ruler"
        :key="index"
      >
        <text
          v-if="time.day"
          :class="$style.day"
          :x="time.x + 1 + '%'"
          y="0"
        >
          {{ time.day }}
        </text>
        <text
          v-if="time.time"
          :class="$style.text"
          :x="time.x + 1 + '%'"
          y="0"
        >
          {{ time.time }}
        </text>
        <rect
          :x="time.x + '%'"
          :class="$style.rulerLine"
        />
      </g>
    </svg>
    <svg :class="$style.lines">
      <g
        v-for="(event, index) in lines"
        :key="index"
      >
        <rect
          :x="event.x"
          :class="$style.line"
          :fill="event.color"
          @click="move(event)"
        />
      </g>
    </svg>
  </div>
</template>

<script>
/* eslint-disable no-await-in-loop */
import dayjs from 'dayjs';
import { InlineSvgComponent } from 'vue-inline-svg';
import ButtonBlueIcon from '@/components/common/button/button-blue-icon.vue';

export default {
  components: {
    inlineSvg: InlineSvgComponent,
    ButtonBlueIcon,
  },
  props: ['data'],
  data: () => ({
    ruler: [],
    isPlaying: false,
    cursor: { x: 0 },
    pause: 3000,
    lines: [],
  }),
  computed: {
    dateFrom() {
      return dayjs(this.$route.query.dateFrom)
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0);
    },
    dateTo() {
      return dayjs(this.$route.query.dateTo)
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
        .set('millisecond', 0);
    },
    daysNumber() {
      return this.dateTo.diff(this.dateFrom, 'day') + 1;
    },
    oneSecondPercent() {
      return 1 / (this.daysNumber * 24 * 36);
    },
  },
  watch: {
    '$route.query': function (query, oldQuery) {
      // eslint-disable-line func-names
      if (
        query.dateFrom !== oldQuery.dateFrom
        || query.dateTo !== oldQuery.dateTo
      ) {
        this.fetch();
      }
    },
    data() {
      this.fetch();
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      const days = this.daysNumber;

      this.ruler = [
        { x: 0, day: this.dateFrom.format('DD.MM') }];

      if (days < 15 && days > 7) {
        for (let i = 1; i < days; i++) {
          this.ruler.push({ x: (100 / days) * i, day: this.dateFrom.add(i, 'day').format('DD.MM') });
        }
      } else if (days < 8) {
        let timeNum = 2;
        let step = 12;
        if (days < 3) {
          timeNum = 4;
          step = 6;
        }

        let time = 0;
        let day = 1;
        const linesNum = days * timeNum;

        for (let i = 1; i < linesNum; i++) {
          time += step;
          if (time > 24) {
            time -= 24;
            day++;
          }
          if (time !== 24) {
            this.ruler.push({ x: (100 / linesNum) * i, time: `${time}:00` });
          } else {
            this.ruler.push({ x: (100 / linesNum) * i, day: this.dateFrom.add(day, 'day').format('DD.MM') });
          }
        }
      }

      if (this.data.length > 0) {
        this.lines = this.data;

        this.lines.forEach((line) => {
          const date = dayjs(new Date(line.date));
          const diff = date.diff(this.dateFrom, 'second');
          const x = diff * this.oneSecondPercent;
          line.x = `${x}%`;
        });

        const { x } = this.lines[0];
        const eventId = this.lines[0].id;
        this.cursor = { x, eventId, index: 0 };
      }
    },
    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    move(event) {
      const { x } = event;
      const eventId = event.id;
      const index = this.lines.findIndex((line) => line.id === eventId);
      this.cursor = { x, eventId, index };
    },
    moveNext() {
      const current = this.lines.findIndex((line) => line.id === this.cursor.eventId);
      const { x } = this.lines[current + 1];
      const eventId = this.lines[current + 1].id;
      this.cursor = { x, eventId, index: current + 1 };
    },
    async play() {
      this.isPlaying = !this.isPlaying;
      if (this.isPlaying) {
        for (let i = this.cursor.index; i < this.lines.length; i++) {
          if (this.isPlaying) {
            if (this.cursor.index !== i) i = this.cursor.index;
            const { x } = this.lines[i];
            const eventId = this.lines[i].id;
            this.cursor = { x, eventId, index: i + 1 };

            let { pause } = this;
            if (this.lines[i].duration) pause = this.lines[i].duration * 1000;
            await this.timeout(pause);
          }
        }
        this.isPlaying = false;
      }
    },
  },
};
</script>

<style lang="scss" module>
.svg {
  margin-left: 5%;
  width: 90%;
}

.mainLine {
  height: 5px;
  y: 90%;
  width: 100%;
}

.lines {
  width: 90%;
  margin-left: 5%;
  margin-top: -140px;
}

.ruler {
  width: 90%;
  margin-left: 5%;
  margin-top: -62px;
  overflow: visible;
}

.line {
  width: 3px;
  height: 40px;
  cursor: pointer;
}

.rulerLine {
  width: 2px;
  height: 70px;
}

.day {
  font-weight: 500;
}

.item {
  margin-top: 25%;
  display: flex;
  justify-content: center;
  margin-bottom: -40px;
  margin-right: 10px;
  position: relative;
  z-index: 10;
  gap: 10px;
}

.playSvg {
  height: 15px;
  width: 15px;
}

.cursor {
  margin-top: -50px;
  width: 90%;
  height: 50px;
  margin-left: 5%;
  overflow: visible;
}

.cursorSvg {
  width: 10px;
  height: 50px;
  cursor: pointer;
}
</style>

<style>
.button-blue-icon_button_3_TlE {
  padding: 15px;
}
</style>
