<template>
  <layout-navigation>
    <section :class="[
      $style.page,
      {
        [$style.fit]: isParallel,
      }]"
    >
      <header :class="$style.header">
        <div :class="$style.control">
          <date-range-picker
            :value="dateRange"
            :clearable="false"
            :class="$style.datePicker"
            :disable="isParallel"
            @inputDateFrom="dateFromChanged"
            @inputDateTo="dateToChanged"
          />

          <div
            v-if="isParallel"
            :class="$style.control"
          >
            <div
              :class="$style.box"
              @click="isParallel = false"
            >
              <img
                :class="$style.arrow"
                src="@/assets/images/icons/navigation/menu.svg"
              >
            </div>
            <div :class="[
              $style.box,
              $style.activeBox,
            ]"
            >
              <img
                :class="$style.arrow"
                src="@/assets/images/icons/navigation/menu-rotated-selected.svg"
              >
            </div>
          </div>
          <div
            v-else
            :class="[$style.control, { [$style.hide]: hide }]"
          >
            <div :class="[
              $style.box,
              $style.activeBox,
            ]"
            >
              <img
                :class="$style.arrow"
                src="@/assets/images/icons/navigation/menu-selected.svg"
              >
            </div>
            <div
              :class="$style.box"
              @click="isParallel = true"
            >
              <img
                :class="$style.arrow"
                src="@/assets/images/icons/navigation/menu-rotated.svg"
              >
            </div>
          </div>
        </div>

        <div :class="$style.control">
          <refresh
            :class="$style.mr"
            text="Обновить данные"
            @click="refresh"
          />
        </div>
      </header>

      <div :class="$style.mainContainer">
        <div :class="[$style.modeName, { [$style.hide]: hide }]">
          {{ currentMode }}
        </div>

        <div :class="$style.container">
          <div
            v-if="isParallel"
            class="searchLogs"
          >
            <treeselect
              :value="selectedComputer"
              :multiple="false"
              :options="computers"
              :clearable="true"
              :open-on-click="true"
              placeholder="Выбор пользователей"
              :default-expand-level="1"
              :normalizer="normalizer"
              :disable-branch-nodes="true"
            />
          </div>
          <div
            v-else
            class="searchLogs"
          >
            <treeselect
              v-model="selectedComputers"
              :multiple="true"
              :options="computers"
              :clearable="true"
              :open-on-click="true"
              placeholder="Выбор пользователей"
              value-consists-of="LEAF_PRIORITY"
              :default-expand-level="1"
              :normalizer="normalizer"
              :disable-branch-nodes="false"
            />
          </div>

          <div class="searchLogs">
            <treeselect
              v-model="selectedLogs"
              :multiple="true"
              :options="logs"
              :clearable="true"
              :open-on-click="true"
              placeholder="Выбор категорий"
              value-consists-of="LEAF_PRIORITY"
              :default-expand-level="1"
              :normalizer="normalizer"
            />
          </div>

          <button
            :class="$style.button"
            @click="getFeed()"
          >
            Показать
          </button>
        </div>
      </div>

      <div v-show="!isParallel && state === reportEnum.tableLoading">
        <loader />
      </div>

      <div
        v-show="!isParallel && state === reportEnum.tableReady"
        :class="$style.ready"
      >
        Все события загружены
        <img
          src="@/assets/images/icons/navigation/confirm.svg"
          :class="$style.saveIcon"
        >
      </div>
      <group-table
        v-if="!isParallel && state !== reportEnum.requestAwaiting"
        :table-data="pagedData"
        empty-result-string="Нет данных за выбранный период"
        :columns="columns"
        :is-feed="true"
        @changeSort="changeSort"
        @filterClick="filterClick"
      />

      <div v-if="isParallel">
        <div />
        <timeline :data="parallelData" />
      </div>
    </section>
  </layout-navigation>
</template>

<script>
import Timeline from '@/views/computers/advanced/timeline.vue';
import LayoutNavigation from '@/layouts/navigation.vue';
import Refresh from '@/components/common/filter/refresh.vue';
import DateRangePicker from '@/components/common/filter/separated-date-picker.vue';
import dayjs from 'dayjs';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import {
  getComputers,
  getScreenshotLink, getClipboardFileLink,
} from '@/api/methods/computers';
// eslint-disable-next-line
import Worker from 'worker-loader!@/components/common/logs/advanced-feed-worker';
import { logTypes } from '@/assets/scripts/dictionaries';
import GroupTable from '@/components/common/table/tableWithGrouping.vue';
import Loader from '@/components/common/blocks/loader.vue';
import {
  convertSecondsToDays,
} from '@/helpers/format-date';
import {
  messengerName,
  directionName,
} from '@/components/pages/computers/log/messengers/filters';
import { status } from '@/components/pages/computers/log/programs/utils';
import { action as instProgAction } from '@/components/pages/computers/log/installed-programs/utils';
import { action as filesAction } from '@/components/pages/computers/log/files/utils';
import { type } from '@/components/pages/computers/log/web-mail/utils';
import { type as clipboardType } from '@/components/pages/computers/log/clipboard/utils';
import {
  source,
  action,
  available,
} from '@/components/pages/computers/log/shadow-copyings/utils';
import {
  driveType,
  action as drivesAction,
} from '@/components/pages/computers/log/external-drives/utils';
import { action as netFilesAction } from '@/components/pages/computers/log/network-files/utils';
import { action as hardwareAction } from '@/components/pages/computers/log/hardware-changes/utils';
import { action as computerAction } from '@/components/pages/computers/log/computer/utils';
import { action as networkAction } from '@/components/pages/computers/log/network-accesses/utils';

export default {
  components: {
    LayoutNavigation,
    Refresh,
    DateRangePicker,
    Treeselect,
    GroupTable,
    Loader,
    Timeline,
  },
  data: () => ({
    hide: true,
    isParallel: false,
    parallelData: [],
    selectedLogs: [],
    logs: [],
    computers: [],
    selectedComputers: [],
    selectedComputer: null,
    pages: [],
    pagedData: [],
    workers: [],
    workersNumber: 10,
    columns: [
      {
        name: 'Время события',
        width: '10%',
        widthNum: 10,
        field: 'date',
        sort: 1,
        index: 0,
        filter: [],
        selected: [],
        showFilter: false,
      },
      {
        name: 'Пользователь',
        width: '10%',
        widthNum: 10,
        field: 'username',
        sort: 0,
        index: 1,
        filter: [],
        selected: [],
        showFilter: false,
      },
      {
        name: 'Категория',
        width: '10%',
        widthNum: 10,
        field: 'logName',
        sort: 0,
        index: 2,
        filter: [],
        selected: [],
        showFilter: false,
      },

      {
        name: 'Подробности',
        width: '70%',
        widthNum: 70,
        field: 'details',
        sort: 0,
        index: 3,
        filter: [],
        selected: [],
        showFilter: false,
      },
    ],
    logTypes: {
      1:
        [
          {
            text: 'Заголовок окна',
            field: 'caption',
          },
          {
            text: 'Текст',
            field: 'keys',
          },
          {
            text: 'Путь',
            field: 'path',
          },
        ],
      2:
        [
          {
            text: 'Заголовок окна',
            field: 'caption',
          },
          {
            text: 'Путь',
            field: 'path',
          },
          {
            text: 'Скриншот',
            field: 'ssh',
          },
        ],
      3:
        [
          {
            text: 'Заголовок окна',
            field: 'caption',
          },
          {
            text: 'Путь',
            field: 'path',
          },
          {
            text: 'Категория',
            field: 'category',
          },
          {
            text: 'Программа',
            field: 'name',
          },
          {
            text: 'Действие',
            field: 'status',
          },
        ],
      4:
        [
          {
            text: 'Путь',
            field: 'path',
          },
          {
            text: 'Действие',
            field: 'action',
          },
          {
            text: 'Размер',
            field: 'size',
          },
          {
            text: 'MD5',
            field: 'crc',
          },
        ],
      5:
        [
          {
            text: 'Заголовок окна',
            field: 'caption',
          },
          {
            text: 'Тип данных',
            field: 'type',
          },
          {
            text: 'Содержимое буфера обмена',
            field: 'text',
          },
          {
            text: 'Скриншот',
            field: 'ssh',
          },
          {
            text: 'file',
            field: 'file',
          },
        ],
      6:
        [
          {
            text: 'Принтер',
            field: 'printer',
          },
          {
            text: 'Документ',
            field: 'document',
          },
          {
            text: 'Распечатанных страниц',
            field: 'pagesPrinted',
          },
          {
            text: 'Копий',
            field: 'copies',
          },
          {
            text: 'Компьютер',
            field: 'computer',
          },
          {
            text: 'Стоимость',
            field: 'cost',
          },
          {
            text: 'file',
            field: 'file',
          },
        ],
      7:
        [
          {
            text: 'Заголовок окна',
            field: 'caption',
          },
          {
            text: 'Действие',
            field: 'action',
          },
          {
            text: 'Путь',
            field: 'path',
          },
        ],
      8:
        [
          {
            text: 'Действие',
            field: 'action',
          },
          {
            text: 'Буква диска',
            field: 'driveChar',
          },
          {
            text: 'Метка диска',
            field: 'driveLabel',
          },
          {
            text: 'Тип диска',
            field: 'driveType',
          },
          {
            text: 'Файловая система',
            field: 'filesystem',
          },
          {
            text: 'Серийный номер',
            field: 'serial',
          },
        ],
      10:
        [
          {
            text: 'Заголовок окна',
            field: 'caption',
          },
          {
            text: 'Категория',
            field: 'category',
          },
          {
            text: 'Ссылка',
            field: 'link',
          },
          {
            text: 'Время на сайте',
            field: 'duration',
          },
        ],
      11: [{
        text: 'Действие',
        field: 'action',
      }],
      14:
        [
          {
            text: 'Действие',
            field: 'action',
          },
          {
            text: 'Имя файла',
            field: 'filename',
          },
          {
            text: 'Размер файла',
            field: 'filesize',
          },
          {
            text: 'Размещение файла',
            field: 'available',
          },
          {
            text: 'Тип',
            field: 'source',
          },
          {
            text: 'MD5',
            field: 'crc',
          },
          {
            text: 'file',
            field: 'file',
          },
        ],
      15:
        [
          {
            text: 'Дата отправки',
            field: 'dateSend',
          },
          {
            text: 'Тема письма',
            field: 'subject',
          },
          {
            text: 'От кого',
            field: 'from',
          },
          {
            text: 'Кому',
            field: 'to',
          },
          {
            text: 'Текст',
            field: 'text',
          },
        ],
      18:
        [
          {
            text: 'Компьютер',
            field: 'computerName',
          },
          {
            text: 'Действие',
            field: 'action',
          },
        ],
      19:
        [
          {
            text: 'Имя файла',
            field: 'filepath',
          },
          {
            text: 'Действие',
            field: 'action',
          },
          {
            text: 'Права доступа',
            field: 'fileaccess',
          },
        ],
      20:
        [
          {
            text: 'Дата отправки',
            field: 'dateSend',
          },
          {
            text: 'Тема письма',
            field: 'subject',
          },
          {
            text: 'От кого',
            field: 'from',
          },
          {
            text: 'Кому',
            field: 'to',
          },
          {
            text: 'Текст',
            field: 'text',
          },
          {
            text: 'Тип сообщения',
            field: 'type',
          },
          {
            text: 'Сервер почты',
            field: 'server',
          },
        ],
      21:
        [
          {
            text: 'Ссылка',
            field: 'url',
          },
          {
            text: 'Сервер',
            field: 'host',
          },
        ],
      22:
        [
          {
            text: 'Собеседник',
            field: 'buddy',
          },
          {
            text: 'Социальная сеть',
            field: 'socialDomain',
          },
          {
            text: 'Текст',
            field: 'text',
          },
        ],
      23:
        [
          {
            text: 'Мессенджер',
            field: 'messenger',
          },
          {
            text: 'Собеседник',
            field: 'interlocutor',
          },
          {
            text: 'Тип сообщения',
            field: 'direction',
          },
          {
            text: 'Текст',
            field: 'text',
          },
          {
            text: 'Имя в мессенджере',
            field: 'usernameMessenger',
          },
        ],
      25:
        [
          {
            text: 'Действие',
            field: 'action',
          },
          {
            text: 'Тип устройства',
            field: 'hardwareType',
          },
          {
            text: 'Описание',
            field: 'description',
          },
        ],
      26:
        [
          {
            text: 'Поисковик',
            field: 'engine',
          },
          {
            text: 'Текст запроса',
            field: 'query',
          },
        ],
    },
    lastIds: {
    },
    reportEnum: Object.freeze({
      tableReady: 1,
      requestAwaiting: 2,
      tableLoading: 3,
    }),
    state: 2,
    pagesDone: 0,
    pagesNumber: undefined,
  }),
  computed: {
    dateRange() {
      if (!this.$route.query.dateFrom || !this.$route.query.dateTo) {
        this.whenUpdated();
      }
      return [
        new Date(this.$route.query.dateFrom),
        new Date(this.$route.query.dateTo),
      ];
    },
    currentMode() {
      if (this.isParallel) return 'Параллельный режим';
      return 'Последовательный режим';
    },
  },
  watch: {
    workers: {
      handler(newWorkers) {
        const worker = newWorkers.find((one) => one.isBusy === false);

        if (worker) {
          const index = this.pages.findIndex((i) => i.done === false);

          if (index > -1) {
            worker.isBusy = true;
            this.pages[index].done = true;

            const dateFrom = dayjs(this.pages[index].day)
              .set('hour', 0)
              .set('minute', 0)
              .set('second', 0)
              .set('millisecond', 0);

            worker.worker.postMessage(JSON.stringify({
              index,
              computerIds: this.selectedComputers,
              logTypeSlug: this.pages[index].log,
              dateFrom,
              dateTo: this.pages[index].day,
            }));
          }
        }
      },
      deep: true,
    },
  },
  async created() {
    const logs = [
      {
        id: 0,
        name: 'Выбрать все',
        children: [],
      },
    ];
    const allowedLogs = this.$store.getters['user/allowedTypes'];
    logs[0].children = allowedLogs;
    this.logs = logs;

    await getComputers().then(({ tree }) => {
      tree.forEach((item) => this.changeId(item));
      this.computers = tree;
    });
  },
  methods: {
    changeId(item) {
      if (item.type === 'folder') item.id = `folder${item.id}`;
      if (item.children) item.children.forEach((child) => this.changeId(child));
    },
    filterClick(column) {
      const value = column.showFilter;

      column.showFilter = !value;

      if (column.showFilter) {
        window.addEventListener('click', (e) => {
          if (
            !document
              .getElementById(`columnFilter${column.field}`)
              .contains(e.target)
            && !document
              .getElementById(`filterIcon${column.field}`)
              .contains(e.target)
          ) {
            column.showFilter = false;
            window.removeEventListener('click', e);
          }
        });
      }
    },
    changeSort(column) {
      const { sort } = column;
      this.columns.forEach((col) => {
        col.sort = 0;
      });

      if (sort === 1) {
        column.sort = 2;
      } else column.sort = 1;
    },
    async getFeed() {
      if (this.selectedLogs.length > 0) {
        this.dateFrom = dayjs(this.$route.query.dateFrom)
          .set('hour', 0)
          .set('minute', 0)
          .set('second', 0)
          .set('millisecond', 0);

        this.dateTo = dayjs(this.$route.query.dateTo)
          .set('hour', 23)
          .set('minute', 59)
          .set('second', 59)
          .set('millisecond', 0);

        if (!this.isParallel && this.selectedComputers.length > 0) {
          this.pagesDone = 0;
          this.state = this.reportEnum.tableLoading;

          for (let i = 0; i < this.selectedLogs.length; i++) {
            const slug = logTypes.find((log) => log.id === this.selectedLogs[i]);
            const res = {};
            for (let j = 0; j < this.selectedComputers.length; j++) {
              let lastId = 0;
              if (this.selectedLogs[i] !== 23) {
                lastId = this.$store.getters['historyLogs/getLastId'](slug.slug, this.selectedComputers[j]);
              } else {
                const lastText = this.$store.getters['historyLogs/getLastId']('messengerstext',
                  this.selectedComputers[j]);
                const lastFile = this.$store.getters['historyLogs/getLastId']('messengersfile',
                  this.selectedComputers[j]);

                lastId = lastText > lastFile ? lastText : lastFile;
              }
              res[this.selectedComputers[j]] = lastId;
            }
            this.lastIds[this.selectedLogs[i]] = res;
          }

          const days = this.dateTo.diff(this.dateFrom, 'day');
          this.pagesNumber = (days + 1) * this.selectedLogs.length;

          this.pages = [];
          this.pagedData = [];

          for (let j = 0; j < days + 1; j++) {
            for (let i = 0; i < this.selectedLogs.length; i++) {
              const slug = logTypes.find((log) => log.id === this.selectedLogs[i]);
              this.pages.push({ done: false, log: slug.slug, day: this.dateTo.subtract(j, 'day') });
            }
          }

          this.assignWorkers();
        } else {
          this.parallelData = [
            { color: 'blue', date: '2023-04-19 09:14:01.000Z"', id: 2, logId: 4 },
            { color: 'green', date: '2023-04-19 15:14:01.000Z"', id: 5, logId: 6 },
            { color: 'blue', date: '2023-04-19 19:14:01.000Z"', id: 7, logId: 4 },
            { color: 'red', date: '2023-04-20 09:14:01.000Z"', id: 1, logId: 1 },
            { color: 'red', date: '2023-04-20 12:14:01.000Z"', id: 9, logId: 1 },
            { color: 'green', date: '2023-04-20 19:14:01.000Z"', id: 3, logId: 6 },
          ];
        }
      }
    },
    assignWorkers() {
      this.workers = [];
      let workersNum = this.workersNumber;

      if (this.pages.length < workersNum) {
        workersNum = this.pages.length;
      }

      for (let i = 0; i < workersNum; i++) {
        this.workers.push(this.createWorker(i));
      }
    },
    createWorker(workerIndex) {
      const obj = {
        worker: new Worker(), isBusy: false,
      };
      obj.worker.onmessage = (result) => {
        const res = JSON.parse(result.data);

        if (res.items === 'error') {
          this.pages[res.index] = false;
        } else {
          this.onDataRecieved(res);
        }

        this.workers[workerIndex].isBusy = false;
      };

      return obj;
    },
    onDataRecieved(data) {
      this.pagesDone += 1;

      if (data.items) {
        data.items.forEach((item) => {
          const logFields = this.logTypes[item.logId];

          if (logFields) {
            item.details = '<div>';
            logFields.forEach((fieldItem) => {
              let value = item[fieldItem.field];

              switch (item.logId) {
                case 3: {
                  if (fieldItem.field === 'status') value = status(value);
                  break;
                }
                case 4: {
                  if (fieldItem.field === 'action') value = filesAction(value);
                  break;
                }
                case 5: {
                  if (fieldItem.field === 'type') value = clipboardType(value);
                  break;
                }
                case 7: {
                  if (fieldItem.field === 'action') value = instProgAction(value);
                  break;
                }
                case 8: {
                  if (fieldItem.field === 'action') value = drivesAction(value);
                  if (fieldItem.field === 'driveType') value = driveType(value);
                  break;
                }
                case 10: {
                  if (fieldItem.field === 'duration') value = convertSecondsToDays(value);
                  break;
                }
                case 11: {
                  if (fieldItem.field === 'action') value = computerAction(value);
                  break;
                }
                case 14: {
                  if (fieldItem.field === 'action') value = action(value);
                  if (fieldItem.field === 'available') value = available(value);
                  if (fieldItem.field === 'source') value = source(value);
                  break;
                }
                case 18: {
                  if (fieldItem.field === 'action') value = networkAction(value);
                  break;
                }
                case 19: {
                  if (fieldItem.field === 'action') value = netFilesAction(value);
                  break;
                }
                case 20: {
                  if (fieldItem.field === 'type') value = type(value);
                  break;
                }
                case 23: {
                  if (fieldItem.field === 'messenger') value = messengerName(value);
                  if (fieldItem.field === 'direction') value = directionName(value);
                  break;
                }
                case 25: {
                  if (fieldItem.field === 'action') value = hardwareAction(value);
                  break;
                }
                default: break;
              }

              if (!value) value = '';

              item[fieldItem.field] = value;

              if ((fieldItem.field === 'file')) {
                item.details += '';
              } else if ((fieldItem.field === 'ssh')) {
                if (item.logId === 2) {
                  const url = getScreenshotLink(item.compId, item.path);
                  item.details += ` <img class="ssh" src="${url}">`;
                } else if (item.logId === 5 && item.type === 2) {
                  const url = getClipboardFileLink(item.compId, item.filepath);
                  item.details += ` <img class="ssh" src="${url}">`;
                }
              } else if (item.logId === 5 && fieldItem.field === 'text' && item.type !== 'Текст') item.details += '';
              else {
                item.details += `<div class="detailsItem"> <div class="itemHeader"> 
                ${fieldItem.text}: </div> <div class="itemContent"> ${value} </div> </div>`;
              }
            });
            item.details += '</div>';
          }

          if (item.id > this.lastIds[item.logId][item.compId]) {
            item.isNew = true;

            if (item.logId === 23) {
              if (item.type === 'text') {
                this.$store.dispatch('historyLogs/setLastId', {
                  id: item.id,
                  logTypeSlug: 'messegerstext',
                  idComputer: item.compId,
                });
              }

              if (item.type === 'file') {
                this.$store.dispatch('historyLogs/setLastId', {
                  id: item.id,
                  logTypeSlug: 'messegersfile',
                  idComputer: item.compId,
                });
              }
            } else {
              const { slug } = logTypes.find((log) => log.id === item.logId);

              this.$store.dispatch('historyLogs/setLastId', {
                id: item.id,
                logTypeSlug: slug,
                idComputer: item.compId,
              });
            }
          }
        });

        this.pagedData.push(...data.items);
      }

      if (this.pagesDone === this.pagesNumber) {
        this.state = this.reportEnum.tableReady;
      }
    },
    normalizer(node) {
      let { name } = node;
      if (node.nameUser) name = node.nameUser;
      return {
        label: name,
      };
    },
    whenUpdated() {
      const queryMutations = {};
      const defaultDateQuery = dayjs().format('YYYY-MM-DD'); // TODO: Унификация формата даты

      if (!this.$route.query.dateFrom) {
        queryMutations.dateFrom = defaultDateQuery;
      }

      if (!this.$route.query.dateTo) {
        queryMutations.dateTo = defaultDateQuery;
      }

      if (Object.keys(queryMutations).length) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            ...queryMutations,
          },
        });
      }

      this.updateNeeded = true;
    },
    async refresh() {
      await this.getFeed();
    },
    dateFromChanged(val) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          dateFrom: dayjs(val).format('YYYY-MM-DD'), // TODO: Унификация формата даты
        },
      });
    },
    dateToChanged(val) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          dateTo: dayjs(val).format('YYYY-MM-DD'), // TODO: Унификация формата даты
        },
      });
    },
  },
};
</script>

<style lang="scss" module>
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.page {
  background: $white;
  width: 100%;
}

.fit {
  height: fit-content;
}

.header {
  box-shadow: $shadow;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px 30px;
}

.control {
  display: flex;
  align-items: center;

  .mr {
    margin-right: 30px;
  }
}

.box {
  border: 1px solid #dfdfdf;
  height: 40px;
  display: flex;
  width: 40px;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
  background: #F5F5F5;
  cursor: pointer;
}

.activeBox {
  background: #E9F7FF;
  border-color: #B1D6ED;
}

.datePicker {
  margin-right: 40px
}

.mainContainer {
  display: flex;
  margin: 10px;
  border: 1px solid #c4c4c4;
  overflow: visible;
  min-height: 240px;
  flex-direction: column;
}

.button {
  border: 1px solid white;
  cursor: pointer;
  color: white;
  height: 36px;
  width: 10%;
  background: #2985bf;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 10px 0px 0px;
  min-width: 80px;
}

.container {
  display: flex;
  gap: 5%;
  margin: 0px 10px;
}

.modeName {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px;
  font-weight: 500;
}

.saveIcon {
  width: 20px;
}

.ready {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.hide {
  visibility: hidden;
}
</style>

<style>
.searchLogs {
  display: flex;
  flex-direction: column;
  margin: 5px 0px;
  width: 45%;
}

.searchLogs .vue-treeselect {
  margin-left: 2%;
}

.searchLogs .vue-treeselect__control {
  border-radius: 0px;
  width: 95%;
}

.searchLogs .vue-treeselect__menu {
  width: 95%;
  max-height: 160px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.searchLogs .vue-treeselect__option {
  font-size: 14px;
}

.searchLogs .vue-treeselect__multi-value {
  margin: 3px 0px;
}

.searchLogs .vue-treeselect__multi-value-item-container {
  padding-top: 3px;
}

.searchLogs .iconFolder {
  display: inline-flex;
}

.searchLogs .vue-treeselect__checkbox--checked {
  background-color: #2985bf;
  border: 1px solid #2985bf;
}

.searchLogs .vue-treeselect__check-mark {
  margin: 0.5px;
}

.loader_loader_2-l8m {
  border: 5px solid #f5f5f5;
  border-top: 5px solid #2985bf;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.detailsItem {
  display: flex;
}

.itemHeader {
  margin: 5px;
  font-weight: 500;
  min-width: 100px;
}

.itemContent {
  margin: 5px;
}

.ssh {
  width: 70%;
  margin: 7px;
}

.tableWithGrouping_searchResultContainer_2OxFS {
  height: 100%;
  background: white;
}
</style>
